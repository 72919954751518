import React from "react";
import { Box, Typography, CircularProgress, Grid2, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import AllScoreCard from "./AllScoreCard";
import SearchIcon from "@mui/icons-material/Search";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import SettingsAccessibilityRoundedIcon from "@mui/icons-material/SettingsAccessibilityRounded";
import SpeedRoundedIcon from "@mui/icons-material/SpeedRounded";

const Overview = ({ domainName, websitePhotos, lighthouse }) => {
  const cleanUrl = domainName
    .replace(/(^\w+:|^)\/\/(www\.)?/, "")
    .replace(/\/$/, "");

  // display currentDate
  const currentDate = new Date();
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  const formattedDate = currentDate.toLocaleString("en-US", options);

  const ScoreCard = ({ title, score, icon }) => (
    <AllScoreCard title={title} score={score} icon={icon} />
  );

  const scores = [
    lighthouse?.seoScore,
    lighthouse?.accessibilityScore,
    lighthouse?.bestPracticesScore,
    lighthouse?.performanceScore,
  ];
  const validScores = scores.filter((score) => typeof score === "number");
  const totalSEOScore =
    validScores.length > 0
      ? Math.round(
          validScores.reduce((sum, score) => sum + score, 0) /
            validScores.length
        )
      : 0;

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        margin: "auto",
        marginBottom: "20px",
        border: "1px solid #e2e2e2",
      }}
      id="overview"
    >
      <Grid2 sx={{ flexWrap: { md: "nowrap" } }} container spacing={4}>
        {/* Left Side - Image */}
        <Grid2 sx={{ flexShrink: { lg: 0 } }} xs={12} md={5}>
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              position: { md: "sticky" },
              top: 130,
            }}
          >
            <img
              src={websitePhotos}
              alt="Site preview"
              style={{ width: "100%", borderRadius: "8px", height: "auto" }}
            />
          </Box>
        </Grid2>

        {/* Right Side - Information */}
        <Grid2 xs={12} md={7}>
          <Typography
            fontWeight="bold"
            sx={{ fontSize: "14px", wordBreak: "break-all" }}
          >
            <Link className="link" to={domainName} target="_blank">
              {cleanUrl}
            </Link>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {formattedDate}
          </Typography>

          {/* Progress Bars */}
          <Box
            sx={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            {/* Background Circle (Light Gray) */}
            <CircularProgress
              variant="determinate"
              value={100}
              size={100}
              thickness={3}
              sx={{
                color: "#dfdfdf", // Background circle color
              }}
            />

            {/* Colored Progress (Green & Orange based on value) */}
            <CircularProgress
              variant="determinate"
              value={totalSEOScore}
              size={100}
              thickness={3}
              sx={{
                color:
                  totalSEOScore > 80
                    ? "#22c55e" // green (bg-green-500)
                    : totalSEOScore > 50
                      ? "#f59e0b" // yellow (bg-yellow-500)
                      : "#ef4444", // red (bg-red-500)
                position: "absolute",
                left: 0,
                borderRadius: "50%",
                backgroundColor:
                  totalSEOScore > 80
                    ? "#22c55e1e" // green (bg-green-500)
                    : totalSEOScore > 50
                      ? "#f59e0b1e" // yellow (bg-yellow-500)
                      : "#ef44441e", // red (bg-red-500)
              }}
            />

            {/* Display the score in the center */}
            <Box
              sx={{
                position: "absolute",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h5"
                component="div"
                sx={{ color: "#330b7e", fontWeight: "bold" }}
              >{`${Math.round(totalSEOScore)}%`}</Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginBottom: "20px" }}
            >
              Total SEO Score
            </Typography>
          </Box>

          <Box sx={{ marginBottom: "20px" }}>
            <div className="space-y-6">
              <Grid container spacing={{ xs: 2, md: 3 }}>
                {/* SEO Score */}
                <Grid item xs={12} sm={6} md={12} lg={6}>
                  <ScoreCard
                    title="SEO"
                    score={lighthouse?.seoScore}
                    icon={<SearchIcon sx={{ color: "#330b7e" }} />}
                  />
                </Grid>
                {/* Accessibility Score */}
                <Grid item xs={12} sm={6} md={12} lg={6}>
                  <ScoreCard
                    title="Accessibility"
                    score={lighthouse?.accessibilityScore}
                    icon={
                      <SettingsAccessibilityRoundedIcon
                        sx={{ color: "#330b7e" }}
                      />
                    }
                  />
                </Grid>
                {/* Best Practices Score */}
                <Grid item xs={12} sm={6} md={12} lg={6}>
                  <ScoreCard
                    title="Best Practices"
                    score={lighthouse?.bestPracticesScore}
                    icon={<SpeedRoundedIcon sx={{ color: "#330b7e" }} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={6}>
                  <ScoreCard
                    title="Performance"
                    score={lighthouse?.performanceScore}
                    icon={
                      <WorkspacePremiumRoundedIcon sx={{ color: "#330b7e" }} />
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Overview;
