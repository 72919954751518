import { Alert, AlertTitle } from "@mui/material";
import React from "react";

const InfoLabel = ({ info }) => {
  return (
    <Alert
      severity={info?.status === "success" ? "success" : "warning"}
      sx={{
        "& .MuiAlert-message": {
          overflow: "unset !important",
          wordBreak:"break-word"
        },
      }}
    >
      <AlertTitle>{info?.message}</AlertTitle>
      {"Suggestion: "+ info?.suggestion}
    </Alert>
  );
};

export default InfoLabel;
