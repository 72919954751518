import { Box } from "@mui/material";
import InfoCard from "./InfoCard";
import React from "react";
const BestPracticeList = ({ bestPractice }) => {
  return bestPractice ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      {Object.values(bestPractice).map((item, index) => (
        <InfoCard key={item.id || index} info={item} />
      ))}
    </Box>
  ) : (
    <></>
  );
};

export default BestPracticeList;
