import React from "react";
import "./Loading.css";

const LoadingDots = () => {
  return (
    <span className="saving">
      <span> .</span>
      <span>.</span>
      <span>.</span>
    </span>
  );
};

const TextLoading = () => {
  return (
    <div className="scroll-list">
      <div className="scroll-item">
        Checking SEO Performance
        <LoadingDots />
      </div>
      <div className="scroll-item">
        Analyzing Speed Insights
        <LoadingDots />
      </div>
      <div className="scroll-item">
        Identifying Optimization Opportunities
        <LoadingDots />
      </div>
      <div className="scroll-item">
        Reviewing Best Practices
        <LoadingDots />
      </div>
      <div className="scroll-item">
        Finalizing Results
        <LoadingDots />
      </div>
    </div>
  );
};

export default TextLoading;
