export const colorPalette = {
  bg: {
    success: "#edf7ed",
    good: "#edf7ed",
    missing: "#fff4e5",
    warning: "#fff4e5",
  },

  color: {
    success: "#1e4620",
    good: "#1e4620",
    missing: "#663c00",
    warning: "#663c00",
  },
};
