import { Box, Card, CardContent } from "@mui/material";

const PerformanceCard = ({ children }) => {
  return (
    <Box
      sx={{
        flex: "1 1 calc(50% - 10px)",
        marginBottom: "20px",
      }}
    >
      <Card
        sx={{
          boxShadow: "none",
          border: "1px solid #e2e2e2",
        }}
      >
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
};

export default PerformanceCard;
