import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const GooglePreview = ({ previewData }) => {
  return (
    <Box
      sx={{
        flex: "1 1 calc(50% - 10px)",
        marginBottom: "20px",
      }}
    >
      <Card
        sx={{
          boxShadow: "none",
          border: "1px solid #e2e2e2",
        }}
      >
        <CardContent>
          <Typography variant="h6">Google Preview</Typography>
          <Box>
            <Typography
              sx={{
                color: "#384aae",
              }}
            >
              {previewData?.title}
            </Typography>

            <Typography>
              <Link className="link" to={previewData?.url} target="_blank">
                {previewData?.url}
              </Link>
            </Typography>

            <Typography>{previewData?.description}</Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default GooglePreview;
