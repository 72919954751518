import React from "react";
import BestPracticeList from "../BestPracticeList";
import Heading from "../Heading";
import { Box, Card, CardContent } from "@mui/material";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";

const BestPractices = ({ bestPractice }) => {
  return (
    <Box sx={{ my: "40px" }} id="bestPractices">
      <Card
        sx={{
          boxShadow: "none",
          border: "1px solid #e2e2e2",
          borderRadius: "8px",
        }}
      >
        <CardContent>
          <Heading text={"Best Practice"} Icon={WorkspacePremiumRoundedIcon} />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            {bestPractice ? (
              <BestPracticeList bestPractice={bestPractice} />
            ) : (
              <></>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BestPractices;
