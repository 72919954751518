import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function AllScoreCard({ title, score, icon }) {
  // score display 0.76 to convert 76%
  // if (!Number.isInteger(score)) {
  //   score = Math.abs(score).toString().split(".")[1] || "00";

  //   if (score.length === 1) {
  //     score = score + "0";
  //   }
  // }

  return (
    <Card>
      <CardContent>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body2" fontWeight="600">
              {title}
            </Typography>
            {icon}
          </Box>
          <Typography>{Number(score).toFixed(2)}%</Typography>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#F2F2F2",
              borderRadius: "20px",
              height: "10px",
              margin: "10px 0",
            }}
          >
            <Box
              sx={{
                height: "10px", // equivalent to h-2.5 in Tailwind
                borderRadius: "9999px", // equivalent to rounded-full
                backgroundColor:
                  score > 80
                    ? "#22c55e" // green (bg-green-500)
                    : score > 50
                      ? "#f59e0b" // yellow (bg-yellow-500)
                      : "#ef4444", // red (bg-red-500)
                width: `${score}%`,
              }}
            ></Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
