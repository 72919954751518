import { Box, Card, CardContent } from "@mui/material";
import React from "react";
import Heading from "../Heading";
import Paragraph from "../Paragraph";
import SearchIcon from "@mui/icons-material/Search";
import MetaInfoCard from "./MetaInfoCard";
import GooglePreview from "./GooglePreview";
import HeadingTagInfo from "./HeadingTagInfo";
import AdvanceSEO from "./AdvanceSEO";
import BestPracticeCard from "./BestPracticeCard";

const SEO = ({
  metaTitle,
  metaDescription,
  metaKeywords,
  pageAnalysisData,
  openGraph,
  bestPractice,
}) => {
  return (
    <Box sx={{ my: "40px" }} id="seo">
      <Card
        sx={{
          boxShadow: "none",
          border: "1px solid #e2e2e2",
          borderRadius: "8px",
        }}
      >
        <CardContent sx={{ padding: "20px !important" }}>
          <Heading text={"SEO"} Icon={SearchIcon} />
          <Paragraph
            sx={{ mt: 0.5 }}
            text={`These checks ensure that your page is following basic
              search engine optimization advice. There are many
              additional factors Lighthouse does not score here that
              may affect your search ranking, including performance
              on Core Web Vitals. Learn more about Google Search
              Essentials.`}
          />

          <Box
            sx={{
              display: "block",
              flexWrap: "wrap",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <MetaInfoCard tagName="Meta Title" metaTagInfo={metaTitle} />
            <MetaInfoCard
              tagName="Meta Description"
              metaTagInfo={metaDescription}
            />
            <MetaInfoCard
              tagName="Meta Keywords"
              metaTagInfo={metaKeywords}
              isMetaKeyword
              sx={{ flex: "1 1 calc(50% - 10px)" }}
            />

            <BestPracticeCard tagInfo={bestPractice?.modernImageFormats} />
            <BestPracticeCard tagInfo={bestPractice?.offscreenImages} />
            <BestPracticeCard tagInfo={bestPractice?.unminifiedCss} />
            <BestPracticeCard tagInfo={bestPractice?.unminifiedJavascript} />
            <BestPracticeCard tagInfo={bestPractice?.usesOptimizedImages} />

            <GooglePreview previewData={pageAnalysisData?.googlePreview} />
            <HeadingTagInfo HeadingData={pageAnalysisData?.headingData} />

            <AdvanceSEO
              pageAnalysisData={pageAnalysisData}
              openGraph={openGraph}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SEO;
