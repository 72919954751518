import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function Header({ domainName }) {
  let cleanUrl;
  if (domainName) {
    cleanUrl = domainName
      .replace(/(^\w+:|^)\/\/(www\.)?/, "")
      .replace(/\/$/, "");
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#330b7e",
          padding: "20px",
          display: "Grid",
          placeItems: "center",
          position: "sticky",
          top: -1,
          zIndex: 99,
        }}
      >
        <Typography
          sx={{
            minWidth: 100,
            color: "#fff",
            fontSize: { xs: "14px", md: "20px", lg: "24px" },
            fontWeight: "600",
          }}
        >
          {cleanUrl && `SEO Results for `}
          <Typography
            component={"a"}
            href={cleanUrl}
            rel="noopener"
            target="_blank"
            className="hyper-link"
            sx={{
              all: "inherit",
              display: "inline",
              "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
          >
            {cleanUrl}
          </Typography>
        </Typography>
      </Box>
    </React.Fragment>
  );
}

export default Header;
