import { Stack, Typography } from "@mui/material";
import React from "react";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { colorPalette } from "../constant/colorPalette";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const StatusLabel = ({ icon, message, sx = {} }) => {
  return (
    <Stack
      sx={{
        mt: "12px",
        flexDirection: "row",
        padding: "12px",
        borderRadius: "12px",
        backgroundColor: colorPalette.bg[icon],
        color: colorPalette.color[icon],
        alignItems: "flex-start",
        gap: "12px",
        ...sx,
      }}
    >
      {icon === "success" ? (
        <TaskAltIcon sx={{ color: "#2e7d32", width: "22px", height: "22px" }} />
      ) : icon === "warning" ? (
        <ErrorOutlineOutlinedIcon
          sx={{ color: "#f59e0b", width: "22px", height: "22px" }}
        />
      ) : (
        <ErrorOutlineOutlinedIcon
          sx={{ color: "#ef4444", width: "22px", height: "22px" }}
        />
      )}
      <Typography sx={{ fontSize: "16px", wordBreak: "break-word" }}>
        {message}
      </Typography>
    </Stack>
  );
};

export default StatusLabel;
