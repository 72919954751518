import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import AccessibilityList from "../AccessibilityList";
import Heading from "../Heading";
import SpeedRoundedIcon from "@mui/icons-material/SpeedRounded";
import PerformanceCard from "./PerformanceCard";

const Performance = ({ lighthouse, accessibility }) => {
  return (
    <Card
      id="performance"
      sx={{
        boxShadow: "none",
        border: "1px solid #e2e2e2",
        borderRadius: "8px",
        my: "40px",
      }}
    >
      <CardContent sx={{ padding: "20px !important" }}>
        <Heading text={"Performance"} Icon={SpeedRoundedIcon} />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          {/* firstContentfulPaint */}
          <PerformanceCard>
            <Typography
              variant="h6"
              sx={{
                color: (() => {
                  const value = parseFloat(
                    lighthouse?.webVitals?.firstContentfulPaint?.displayValue
                  );
                  if (value <= 1.8) return "#22c55e"; // Success
                  if (value > 1.8 && value <= 3) return "#f59e0b"; // Warning
                  return "#ef4444"; // Danger
                })(),
              }}
            >
              {lighthouse?.webVitals?.firstContentfulPaint?.displayValue}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {lighthouse?.webVitals?.firstContentfulPaint?.title}
            </Typography>
          </PerformanceCard>

          {/* speedIndex */}
          <PerformanceCard>
            <Typography
              variant="h6"
              sx={{
                color: (() => {
                  const value = parseFloat(
                    lighthouse?.webVitals?.speedIndex?.displayValue
                  );
                  if (value <= 3.4) return "#22c55e"; // Success (Green)
                  if (value > 3.4 && value <= 5.8) return "#f59e0b"; // Warning (Orange)
                  return "#ef4444"; // Danger (Red)
                })(),
              }}
            >
              {lighthouse?.webVitals?.speedIndex?.displayValue}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              {lighthouse?.webVitals?.speedIndex?.title}
            </Typography>
          </PerformanceCard>

          {/* largestContentfulPaint */}
          <PerformanceCard>
            <Typography
              variant="h6"
              sx={{
                color: (() => {
                  const value = parseFloat(
                    lighthouse?.webVitals?.largestContentfulPaint?.displayValue
                  );
                  if (value <= 2.5) return "#22c55e"; // Success (Green)
                  if (value > 2.5 && value <= 4) return "#f59e0b"; // Warning (Orange)
                  return "#ef4444"; // Danger (Red)
                })(),
              }}
            >
              {lighthouse?.webVitals?.largestContentfulPaint?.displayValue}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              {lighthouse?.webVitals?.largestContentfulPaint?.title}
            </Typography>
          </PerformanceCard>

          {/* cumulativeLayoutShift */}
          <PerformanceCard>
            <Typography
              variant="h6"
              sx={{
                color: (() => {
                  const value = parseFloat(
                    lighthouse?.webVitals?.cumulativeLayoutShift?.displayValue
                  );
                  if (value <= 0.1) return "#22c55e"; // Good (Green)
                  if (value > 0.1 && value <= 0.25) return "#f59e0b"; // Needs Improvement (Orange)
                  return "#ef4444"; // Poor (Red)
                })(),
              }}
            >
              {lighthouse?.webVitals?.cumulativeLayoutShift?.displayValue}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              {lighthouse?.webVitals?.cumulativeLayoutShift?.title}
            </Typography>
          </PerformanceCard>

          {/* totalBlockingTime */}
          <PerformanceCard>
            <Typography
              variant="h6"
              sx={{
                color: (() => {
                  const value = parseFloat(
                    lighthouse?.webVitals?.totalBlockingTime?.displayValue
                  );
                  if (value <= 200) return "#22c55e"; // Good (Green)
                  if (value > 200 && value <= 600) return "#f59e0b"; // Needs Improvement (Orange)
                  return "#ef4444"; // Poor (Red)
                })(),
              }}
            >
              {lighthouse?.webVitals?.totalBlockingTime?.displayValue}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              {lighthouse?.webVitals?.totalBlockingTime?.title}
            </Typography>
          </PerformanceCard>

          {accessibility ? (
            <AccessibilityList accessibility={accessibility} />
          ) : (
            <></>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default Performance;
