import { TabPanel } from "@mui/lab";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import StatusChip from "../StatusChip";

const HeadingTabPanel = ({ tagName, tabValue, tagInfo }) => {
  return (
    <TabPanel value={tabValue}>
      <Box>
        <Typography variant="subtitle1">
          <strong>{tagName}:</strong> Count: {tagInfo?.count} | Status:{" "}
          <StatusChip status={tagInfo?.status} />
        </Typography>
        <Typography variant="body2">
          Status Note: {tagInfo?.statusNote}
        </Typography>
        {/* Display the content in a table */}
        {tagInfo?.content.length !== 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Index</TableCell>
                <TableCell>Content</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tagInfo?.content.length ? (
                tagInfo?.content.map((item, index) => (
                  <TableRow key={item}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item}</TableCell>
                  </TableRow>
                ))
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        )}
      </Box>
    </TabPanel>
  );
};

export default HeadingTabPanel;
