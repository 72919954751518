import { useState, useCallback } from "react";
import { instance } from "../api/instance";

export default function useApi() {
  const [isLoading, setIsLoading] = useState(false);
  const apiCall = useCallback(async function (
    config = { url: "", method: "", headers: "", params: {}, data: {} }
  ) {
    const apiInstance = instance;

    try {
      setIsLoading(true);
      const res = await apiInstance(config);
      setIsLoading(false);
      return res;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  },
  []);
  return {
    isLoading,
    setIsLoading,
    apiCall,
  };
}
