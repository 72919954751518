import React from "react";
import {
  Box,
  Card,
  IconButton,
  Typography,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => navigate("/seo-analyzer")}
      sx={{
        textAlign: "center",
        backgroundColor: "#330b7e",
        height: "100vh",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        cursor: "pointer",
      }}
    >
      {/* Title/Logo Section */}
      <Box sx={{ marginBottom: "20px" }}>
        <img
          src={
            "https://cdn.prod.website-files.com/6390c443fe95660a5e9a4a2b/6390cf8ec81424b8d5722600_Vix_Logo-18.png"
          }
          alt="SEO Analyzer Logo"
          style={{ maxWidth: "25%" }}
        />
      </Box>

      {/* Main Card Section */}
      <Card
        sx={{
          width: "90%",
          maxWidth: "450px",
          borderRadius: "8px",
          boxShadow: 3,
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "flex-start",
          padding: "20px",
        }}
      >
        {/* Left Side Icon */}
        <Box>
          <Box sx={{ paddingRight: "10px" }}>
            <IconButton sx={{ color: "#330b7e" }}>
              <SearchOutlined />
            </IconButton>
          </Box>
        </Box>

        {/* Right Side SEO Checker Info */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: "0 20px",
          }}
        >
          <Typography
            sx={{
              color: "#330b7e",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            SEO Checker
          </Typography>
          <Typography
            sx={{
              color: "#666",
              fontSize: "14px",
              marginTop: "5px",
              textAlign: "left",
            }}
          >
            Run a free SEO audit on your website to quickly identify
            optimization issues and measure against best practices. Receive a
            detailed SEO analysis in just minutes.
          </Typography>
        </div>
      </Card>
    </Box>
  );
};

export default HomePage;
