import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import Heading from "../Heading";
import SettingsAccessibilityRoundedIcon from "@mui/icons-material/SettingsAccessibilityRounded";
import StatusChip from "../StatusChip";

const Accessability = ({ pageAnalysisData }) => {
  return (
    <Card
      sx={{
        my: "40px",
        boxShadow: "none",
        border: "1px solid #e2e2e2",
        borderRadius: "8px",
      }}
      id="accessability"
    >
      <CardContent sx={{ padding: "20px !important" }}>
        <Heading
          Icon={SettingsAccessibilityRoundedIcon}
          text={"Accessibility Details"}
        />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Typography variant="subtitle1">
            <strong>Total images:</strong> Count:{" "}
            {pageAnalysisData?.imageAnalysis?.totalImages} | Status:{" "}
            <StatusChip
              status={pageAnalysisData?.imageAnalysis?.details[0]?.status}
            />
          </Typography>
          <Typography variant="body2">
            Status Note: {pageAnalysisData?.imageAnalysis?.details[0]?.message}
          </Typography>
          {/* Display the content in a table */}
          {pageAnalysisData?.imageAnalysis?.details[0]?.code?.length !== 0 && (
            <Box
              sx={{
                overflow: "hidden",
                border: "1px solid #e2e2e2",
                borderRadius: "4px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  overflowX: "auto",
                  maxHeight: { xs: "520px", md: "700px" },
                  overflowY: "auto",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          background: "#f9f9f9",
                          fontWeight: 600,
                        }}
                      >
                        Index
                      </TableCell>
                      <TableCell
                        sx={{
                          background: "#f9f9f9",
                          fontWeight: 600,
                        }}
                      >
                        Content
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pageAnalysisData?.imageAnalysis?.details[0]?.code?.map(
                      (item, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ maxWidth: "20px" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell>{item}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default Accessability;
