import React from "react";
import StatusLabel from "../StatusLabel";
import Paragraph from "../Paragraph";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import StatusIcon from "../StatusIcon";
import KeywordDetailsList from "../KeywordDetailsList";

const MetaInfoCard = ({
  tagName,
  metaTagInfo,
  isMetaKeyword = false,
  sx = {},
}) => {
  return (
    <Card
      sx={{
        boxShadow: "none",
        border: "1px solid #e2e2e2",
        my: "20px",
        ...sx,
      }}
    >
      <CardContent>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography variant="h6">{tagName}</Typography>
          <StatusIcon status={metaTagInfo?.icon} />
        </Stack>

        {isMetaKeyword &&
        metaTagInfo?.isAvailable &&
        metaTagInfo?.keywords.length ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flexWrap: "wrap",
              mt: 2,
            }}
          >
            {metaTagInfo?.keywords?.map((item, index) =>
              item && item.trim() !== "" ? (
                <Typography
                  key={index} // Add a unique key for each item
                  sx={{
                    fontSize: "12px",
                    color: "#fff",
                    backgroundColor: "#726b6b",
                    padding: "6px 10px",
                    borderRadius: "18px",
                    display: "inline-block",
                    textAlign: "center",
                    flexShrink: 0,
                    lineHeight: 1,
                  }}
                >
                  {item}
                </Typography>
              ) : (
                <></>
              )
            )}
          </Box>
        ) : (
          <></>
        )}

        {!isMetaKeyword ? (
          <Paragraph
            text={`${metaTagInfo?.content || "No " + tagName}`}
            sx={{ mt: 1 }}
          />
        ) : (
          <></>
        )}

        {!isMetaKeyword ? (
          <Paragraph
            text={`Length: ${metaTagInfo?.length} character(s)`}
            sx={{
              mt: 1,
              color: "#726b6b",
              fontSize: "14px",
            }}
          />
        ) : (
          <></>
        )}

        <StatusLabel icon={metaTagInfo?.icon} message={metaTagInfo?.message} />
        {isMetaKeyword ? (
          <KeywordDetailsList
            keywordList={metaTagInfo?.details}
            sx={{ mt: 3 }}
          />
        ) : (
          <></>
        )}
      </CardContent>
    </Card>
  );
};

export default MetaInfoCard;
