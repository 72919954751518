import { Stack, Typography } from "@mui/material";
import React from "react";

const Heading = ({ text, Icon }) => {
  return (
    <Stack sx={{ flexDirection: "row", gap: 1, alignItems:"center" }}>
      {Icon && (
        <Icon
          sx={{
            width: { xs: "20px", md: "28px" },
            height: { xs: "20px", md: "28px" },
          }}
        />
      )}
      <Typography
        sx={{ fontSize: { xs: "20px", md: "24px" }, fontWeight: 700 }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

export default Heading;
