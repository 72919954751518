import React from "react";
// import Header from "./Header/Header";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

function Layout() {
  return (
    <>
      <Box>
        <Outlet />
      </Box>
    </>
  );
}

export default Layout;
