import { Box, Typography } from "@mui/material";
import React from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const AdvanceSEOItem = ({ itemName, itemInfo }) => {
  return (
    <Box my={"20px"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          alignItems: {
            xs: "start",
            md: "center",
          },
          gap: "10px",
        }}
      >
        <Typography sx={{ minWidth: "150px" }}>{itemName}:</Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography>
            {/* <IconButton> */}
            {itemInfo.icon === "success" ? (
              <TaskAltIcon color="success" />
            ) : (
              <WarningAmberIcon color="warning" />
            )}

            {/* </IconButton> */}
          </Typography>
          <Typography
            dangerouslySetInnerHTML={{
              __html: itemInfo?.message,
            }}
          />
        </div>
      </Box>
    </Box>
  );
};

export default AdvanceSEOItem;
