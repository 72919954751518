import { Alert, AlertTitle, Typography } from "@mui/material";
import React from "react";

const InfoCard = ({ info }) => {
  return (
    <Alert
      severity={info?.score === 1 ? "success" : "info"}
      sx={{
        "& .MuiAlert-message": {
          overflow: "unset !important",
          wordBreak: "break-word",
        },
      }}
    >
      <AlertTitle>{info?.title}</AlertTitle>
      <Typography
        dangerouslySetInnerHTML={{
          __html: info?.description,
        }}
      />
    </Alert>
  );
};

export default InfoCard;
