import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import AdvanceSEOItem from "./AdvanceSEOItem";

const AdvanceSEO = ({ pageAnalysisData, openGraph }) => {
  return (
    <Box
      sx={{
        flex: "1 1 calc(50% - 10px)",
        marginBottom: "20px",
      }}
    >
      <Card
        sx={{
          boxShadow: "none",
          border: "1px solid #e2e2e2",
        }}
      >
        <CardContent>
          <Typography variant="h6">Advanced SEO</Typography>
          <AdvanceSEOItem
            itemName="Canonical Tag"
            itemInfo={pageAnalysisData.canonicalTag}
          />

          <AdvanceSEOItem
            itemName="Noindex Meta"
            itemInfo={pageAnalysisData.noIndexMeta}
          />

          <AdvanceSEOItem itemName="OpenGraph Meta" itemInfo={openGraph} />

          <AdvanceSEOItem
            itemName="Robots.txt"
            itemInfo={pageAnalysisData?.robotDotTxt}
          />

          <AdvanceSEOItem
            itemName="Schema Meta Data"
            itemInfo={pageAnalysisData?.schemaMetaData}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default AdvanceSEO;
