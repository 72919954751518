import { Typography } from "@mui/material";
import React from "react";
import { colorPalette } from "../constant/colorPalette";

export default function StatusChip({ status }) {
  return (
    <Typography
      component={"span"}
      sx={{
        p: "4px",
        backgroundColor: colorPalette.bg[status],
        color: colorPalette.color[status],
        borderRadius: "4px",
      }}
    >
      {status}
    </Typography>
  );
}
