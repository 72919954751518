import React from "react";
import StatusLabel from "../StatusLabel";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import StatusIcon from "../StatusIcon";

const BestPracticeCard = ({ tagInfo, sx = {} }) => {
  return tagInfo ? (
    <Card
      sx={{
        boxShadow: "none",
        border: "1px solid #e2e2e2",
        my: "20px",
        ...sx,
      }}
    >
      <CardContent>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography variant="h6">{tagInfo?.title}</Typography>
          <StatusIcon status={tagInfo?.score ? "success" : "warning"} />
        </Stack>

        <StatusLabel
          icon={tagInfo?.score ? "success" : "warning"}
          message={tagInfo?.description}
        />
      </CardContent>
    </Card>
  ) : (
    <></>
  );
};

export default BestPracticeCard;
