import { TabContext, TabList } from "@mui/lab";
import { Box, Card, CardContent, Tab, Typography } from "@mui/material";
import React from "react";
import { headingList } from "../../constant/headingList";
import HeadingTabPanel from "./HeadingTabPanel";

const HeadingTagInfo = ({ HeadingData }) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        flex: "1 1 calc(50% - 10px)",
        marginBottom: "20px",
        minWidth: { xs: "100px", md: "300px" },
      }}
    >
      <Card
        sx={{
          boxShadow: "none",
          border: "1px solid #e2e2e2",
        }}
      >
        <CardContent>
          <Typography variant="h6">Headings</Typography>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {headingList.map(({ label, value }) => (
                    <Tab
                      key={label}
                      index={label}
                      label={label}
                      value={value}
                    />
                  ))}
                </TabList>
              </Box>
              {headingList.map(({ tagName, value }) => (
                <HeadingTabPanel
                  key={tagName}
                  tagName={String(tagName).toUpperCase()}
                  tabValue={value}
                  tagInfo={HeadingData[tagName]}
                />
              ))}
            </TabContext>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default HeadingTagInfo;
