import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import SettingsAccessibilityRoundedIcon from "@mui/icons-material/SettingsAccessibilityRounded";
import SpeedRoundedIcon from "@mui/icons-material/SpeedRounded";

export const sidebarItemList = [
  {
    label: "Overview",
    icon: <HomeIcon />,
    target: "overview",
  },
  { label: "SEO", icon: <SearchIcon />, target: "seo" },
  {
    label: "Accessibility",
    icon: <SettingsAccessibilityRoundedIcon />,
    target: "accessability",
  },
  {
    label: "Performance",
    icon: <SpeedRoundedIcon />,
    target: "performance",
  },
  {
    label: "Best Practices",
    icon: <WorkspacePremiumRoundedIcon />,
    target: "bestPractices",
  },
];
