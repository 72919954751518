import { Box } from "@mui/material";
import InfoCard from "./InfoCard";
import React from "react";
const AccessibilityList = ({ accessibility }) => {
  return accessibility ? (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      {Object.values(accessibility).map((item, index) => (
        <InfoCard key={item.id || index} info={item} />
      ))}
    </Box>
  ) : (
    <></>
  );
};

export default AccessibilityList;
