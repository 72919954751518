import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./page/Home";
import Layout from "./component/Layout";
import PageNotFound from "./page/PageNotFound";
import HomePage from "./page/HomePage";

// A custom component to get the location for meta tags
const MetaHelmet = ({ description, title }) => {
  const location = useLocation();

  return (
    <Helmet>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`${location.origin}${location.pathname}`} />
    </Helmet>
  );
};

const routes = createBrowserRouter([
  {
    Component: Layout,
    children: [
      {
        path: "/",
        Component: HomePage,
        loader: () => {
          return {
            helmet: (
              <MetaHelmet
                description="Explore free tools by Vix Media Group to enhance your website experience. Boost performance, improve usability, and deliver value with our innovative solutions."
                title="Free tools by Vix Media Group for enhancing website experience"
              />
            ),
          };
        },
      },
      {
        path: "/seo-analyzer",
        Component: Home,
        loader: () => {
          return {
            helmet: (
              <MetaHelmet
                description="Run a free SEO audit on your website to quickly identify optimization issues and measure against best practices. Receive a detailed SEO analysis in just minutes."
                title="Seo Analyzer | Vix Media Group"
              />
            ),
          };
        },
      },
      {
        path: "*",
        Component: PageNotFound,
        loader: () => {
          return {
            helmet: (
              <MetaHelmet
                description="Content you are looking for is not found"
                title="Not Found"
              />
            ),
          };
        },
      },
    ],
  },
]);

export default function Routes() {
  return (
    <>
      <RouterProvider router={routes} fallbackElement="loading" />
    </>
  );
}