import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSearchParams } from "react-router-dom";
import { APIS } from "../api/apiList.js";
import useApi from "../hook/useApi.js";
import SuccessAnalyser from "./SuccessAnalyser.jsx";
import TextLoading from "../component/TextLoading.jsx";

function Home() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { apiCall } = useApi();
  const [success, setSuccess] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [searchParams] = useSearchParams();
  const queryUrl = searchParams.get("url");

  const validationSchema = Yup.object({
    url: Yup.string()
      .required("URL is required")
      .test(
        "has-protocol",
        "URL must start with http:// or https://",
        (value) => value?.startsWith("http://") || value?.startsWith("https://")
      )
      .url("Invalid URL format"),
  });

  const formik = useFormik({
    initialValues: {
      url: queryUrl ?? "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const { url } = values;
      setLoading(true);
      setError(null);

      try {
        const data = await apiCall({
          url: APIS.getSiteAnalysis,
          method: "post",
          data: JSON.stringify({ url }),
        });

        if (data && data?.data) {
          setResultData(data?.data);
          setSuccess(true);
        } else {
          setSuccess(false);
          setResultData(null);
          throw new Error("Invalid response structure: No data field.");
        }
      } catch (err) {
        setSuccess(false);
        setResultData(null);
        setError(err?.response?.data?.message ?? err.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const hasRun = useRef(false);

  useEffect(() => {
    if (!hasRun.current) {
      hasRun.current = true;
      if (queryUrl != null) {
        formik.submitForm();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryUrl]);

  useEffect(() => {
    if (success && resultData) {
      window.scrollTo({
        top: 181,
        behavior: "smooth",
      });
    }
  }, [success, resultData]);

  return (
    <div style={{ background: "white" }}>
      <Box
        sx={{
          textAlign: "center",
          backgroundColor: "#330b7e",
          height: success && resultData ? "" : "100vh",
          padding: "20px",
          display: "Grid",
          placeItems: "center",
        }}
      >
        <Box sx={{ width: "100%", maxWidth: "512px" }}>
          <Box>
            <Typography
              sx={{
                minWidth: 100,
                color: "#fff",
                fontSize: { xs: "22px", md: "30px", lg: "40px" },
                fontWeight: "600",
                marginBottom: "20px",
              }}
            >
              Analyze Your SEO
            </Typography>

            {/* Form Section */}
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <TextField
                  label="Enter URL"
                  type="url"
                  name="url"
                  variant="outlined"
                  fullWidth
                  value={formik.values.url}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.url && Boolean(formik.errors.url)}
                  helperText={formik.touched.url && formik.errors.url}
                  InputProps={{
                    style: { color: "#fff" },
                  }}
                  sx={{
                    caret: "#fff",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#fff",
                      },
                      "&:hover fieldset": {
                        borderColor: "#fff",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#fff",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#fff",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#fff",
                    },
                    "input:-internal-autofill-selected": {
                      WebkitBoxShadow: "0 0 0 30px #330b7e inset !important",
                      WebkitTextFillColor: "#ffffff !important",
                    },
                  }}
                />

                <Box>
                  <Button
                    type="submit"
                    disabled={loading}
                    sx={{
                      width: "100%",
                      padding: "8px 16px",
                      backgroundColor: "#fff",
                      color: "#330b7e",
                      height: { md: "55px" },
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                      "&:active": {
                        backgroundColor: "#fff",
                      },
                      "&.Mui-disabled": {
                        backgroundColor: "#fff",
                        color: "#330b7e",
                      },
                    }}
                  >
                    {loading ? (
                      <div style={{ width: "76.078px" }}>
                        <CircularProgress size={28} color="inherit" />
                      </div>
                    ) : (
                      "Analyze"
                    )}
                  </Button>
                </Box>
              </Box>
              {loading && (
                <>
                  <LinearProgress
                    sx={{
                      mt: 3,
                      "&.MuiLinearProgress-root": {
                        backgroundColor: "rgb(255 255 255)",
                        "&>span": {
                          background: "#5810e0db !important",
                        },
                      },
                    }}
                  />
                  <TextLoading />
                </>
              )}
            </form>

            <Box sx={{ display: "flex", marginTop: "5px" }}>
              {error && (
                <Typography color="error" sx={{ marginBottom: "20px" }}>
                  {error}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      {success && resultData && (
        <SuccessAnalyser pageAnalysisData={resultData?.data} />
      )}
    </div>
  );
}

export default Home;
