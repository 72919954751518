import { Box } from "@mui/material";
import React from "react";
import InfoLabel from "./InfoLabel";

const KeywordDetailsList = ({ keywordList, sx={} }) => {
  return keywordList?.length ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        overflowY: "auto",
        maxHeight: { xs: "520px", md: "700px" },
        ...sx
      }}
    >
      {keywordList.map((item, index) => (
        <InfoLabel key={item.id || index} info={item} />
      ))}
    </Box>
  ) : (
    <></>
  );
};

export default KeywordDetailsList;
