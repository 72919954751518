import React from "react";
import { Box, Card, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        textAlign: "center",
        backgroundColor: "#330b7e",
        height: "100vh",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {/* Title/Logo Section */}
      <Box sx={{ marginBottom: "20px" }}>
        <img
          src={
            "https://cdn.prod.website-files.com/6390c443fe95660a5e9a4a2b/6390cf8ec81424b8d5722600_Vix_Logo-18.png"
          }
          alt="SEO Analyzer Logo"
          style={{ maxWidth: "25%" }}
        />
      </Box>

      {/* Main Card Section */}
      <Card
        sx={{
          width: "90%",
          maxWidth: "450px",
          borderRadius: "8px",
          boxShadow: 3,
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          padding: "20px",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            color: "#330b7e",
            fontSize: "24px",
            fontWeight: "600",
            marginBottom: "10px",
          }}
        >
          Page Not Found
        </Typography>
        <Typography
          sx={{
            color: "#666",
            fontSize: "14px",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Sorry, the page you are looking for does not exist. Please check the
          URL or go back to the home page.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
          sx={{ backgroundColor: "#330b7e" }}
        >
          Back to Home
        </Button>
      </Card>
    </Box>
  );
};

export default PageNotFound;
