import React from "react";
import { Box } from "@mui/material";
import Overview from "../component/Overview/Overview.jsx";
import Header from "../component/Header/Header.jsx";
import { ScrollToHash } from "../component/ScrollHash.jsx";
import Sidebar from "../component/Sidebar.jsx";
import SEO from "../component/SEO/SEO.jsx";
import Accessability from "../component/Accessability/Accessability.jsx";
import Performance from "../component/Performance/Performance.jsx";
import BestPractices from "../component/BestPractices/BestPractices.jsx";

function SuccessAnalyser({ pageAnalysisData }) {
  const { socialMeta } = pageAnalysisData;
  const { metaTitle, metaDescription, openGraph, metaKeywords } = socialMeta;
  const { lighthouse } = pageAnalysisData;
  const { bestPractice, accessibility } = lighthouse.webVitals;
  return (
    <>
      <ScrollToHash />
      <Header domainName={pageAnalysisData?.url} />
      <Box
        sx={{
          paddingTop: { xs: "10px", md: "25px" },
        }}
      >
        <Box className="container">
          <Box className="mainGrid">
            <Sidebar />

            <Box className="mainSerchContent" sx={{ padding: "20px" }}>
              {/* Overview */}
              <Overview
                domainName={pageAnalysisData?.url}
                websitePhotos={pageAnalysisData?.lighthouse?.mobileScreenShot}
                lighthouse={pageAnalysisData?.lighthouse}
              />

              {/* show all seo */}
              <SEO
                pageAnalysisData={pageAnalysisData}
                metaTitle={metaTitle}
                metaDescription={metaDescription}
                metaKeywords={metaKeywords}
                openGraph={openGraph}
                bestPractice={lighthouse?.seo}
              />

              <Accessability pageAnalysisData={pageAnalysisData} />
              <Performance
                lighthouse={lighthouse}
                accessibility={accessibility}
              />

              <BestPractices bestPractice={bestPractice} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SuccessAnalyser;
