import React from "react";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
const StatusIcon = ({ status }) => {
  return (
    <>
      {status === "success" ? (
        <TaskAltIcon sx={{ color: "#2e7d32", width: "24px", height: "24px" }} />
      ) : status === "warning" ? (
        <ErrorOutlineOutlinedIcon
          sx={{ color: "#f59e0b", width: "24px", height: "24px" }}
        />
      ) : (
        <ErrorOutlineOutlinedIcon
          sx={{ color: "#ef4444", width: "24px", height: "24px" }}
        />
      )}
    </>
  );
};

export default StatusIcon;
