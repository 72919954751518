import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { sidebarItemList } from "../constant/sidebarList";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Sidebar = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  return (
    <Box className="colSpanTab">
      <List
        sx={{
          position: "sticky",
          top: 150,
        }}
      >
        {sidebarItemList?.map((section, index) => (
          <ListItem
            key={index}
            onClick={() => navigate(`/seo-analyzer#${section.target}`)}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#f5f5f5",
                "&:hover": { backgroundColor: "#f0f0f0" },
              },
              cursor: "pointer",
            }}
          >
            <ListItemIcon {...(isSmallScreen ? { title: section.label } : {})}>
              {section.icon}
            </ListItemIcon>
            {!isSmallScreen && (
              <>
                <ListItemText primary={section.label} />
                <ListItemIcon sx={{ minWidth: 0 }}>
                  <ArrowForwardIosIcon
                    sx={{
                      width: "18px",
                    }}
                  />
                </ListItemIcon>
              </>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Sidebar;
